<template>
  <CardToolbox>
    <SearchHeaderStyle>
      <sdPageHeader title="Product Line">
        <template #subTitle>
          <a-input
            v-model:value="searchValue"
            @change="onHandleSearch"
            placeholder="Search by Name"
          >
            <template #suffix>
              <sdFeatherIcons type="search" productLine="16" />
            </template>
          </a-input>
        </template>
      </sdPageHeader>
    </SearchHeaderStyle>
  </CardToolbox>
  <Main>
    <a-row :gutter="15">
      <a-col class="w-100" :md="14">
        <sdCards title="Product Line">
          <div v-if="isLoading" class="spin">
            <a-spin />
          </div>

          <div v-else>
            <TableWrapper class="table-data-view table-responsive">
              <a-table
                @change="onHandleTableChange"
                :pagination="pagination"
                :dataSource="dataSource"
                :columns="columns"
              />
            </TableWrapper>
          </div>
        </sdCards>
      </a-col>
      <a-col class="w-100" :md="10">
        <sdCards :title="HeaderInfo">
          <FormValidationWrap>
            <VerticalFormStyleWrap>
              <a-form
                name="sDash_validation-form"
                ref="formRef"
                @finish="onSubmit"
                :model="formState"
                :rules="rules"
                :layout="formState.layout"
              >
                <a-row :gutter="30">
                  <a-col :md="24" :xs="24">
                    <a-form-item ref="name" name="name" label="Product Line">
                      <a-input
                        v-model:value="formState.name"
                        placeholder="Product Line"
                      />
                    </a-form-item>
                  </a-col>
                </a-row>
                <div class="sDash_form-action">
                  <sdButton
                    v-if="localState.editionMode"
                    v-on:click="switchUpdateOff()"
                    class="sDash_form-action__btn"
                    productLine="large"
                    type="light"
                  >
                    Cancel
                  </sdButton>
                  <sdButton
                    v-if="localState.editionMode"
                    type="primary"
                    html-type="submit"
                    class="sDash_form-action__btn"
                    productLine="large"
                  >
                    Edit
                  </sdButton>
                  <sdButton
                    v-else
                    type="primary"
                    html-type="submit"
                    class="sDash_form-action__btn"
                    productLine="large"
                  >
                    Add
                  </sdButton>
                </div>
              </a-form>
            </VerticalFormStyleWrap>
          </FormValidationWrap>
        </sdCards>
      </a-col>
    </a-row>
  </Main>
</template>
<script>
import {
  FormValidationWrap,
  VerticalFormStyleWrap,
} from "../forms/overview/Style";
import { Main, TableWrapper, CardToolbox, SearchHeaderStyle } from "../styled";
import { useStore } from "vuex";
import { computed, onMounted, reactive, ref, nextTick } from "vue";

const columns = [
  {
    title: "Product Line",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Actions",
    dataIndex: "action",
    key: "action",
    width: "90px",
  },
];

const ProductLine = {
  name: "ProductLine",
  components: {
    Main,
    TableWrapper,
    FormValidationWrap,
    VerticalFormStyleWrap,
    CardToolbox,
    SearchHeaderStyle,
  },
  setup() {
    const { state, dispatch } = useStore();
    const ProductLineApi = computed(() => state.ProductLineApi.data.data);
    const isLoading = computed(() => state.ProductLineApi.loading);
    const searchValue = ref("");
    var loading = false;
    var productLinePagination = computed(() => state.ProductLineApi.data);
    var sortDirections = "ascend";
    const HeaderInfo = computed(() => {
      if (localState.editionMode) {
        return "Edit Product Line";
      }
      return "Add Product Line";
    });

    const localState = reactive({
      editionMode: false,
    });
    const formRef = ref();
    const formState = reactive({
      layout: "vertical",
      name: "",
      visible: true,
    });

    const rules = {
      name: [
        {
          required: true,
          message: "This field is required",
          trigger: "change",
        },
        {
          min: 2,
          max: 100,
          message: "Field need to be longer then two and shorter then 100",
          trigger: "change",
        },
      ],
    };

    onMounted(() => {
      dispatch("productLineGetPagedData", pagination.value);
      localState.editionMode = false;
    });
    const onHandleTableChange = (pagination) => {
      pagination = {
        ...pagination,
        sorter: {
          order: sortDirections,
        },
        current: pagination.current,
        OrderBy: sortDirections,
        searchValue: searchValue.value,
      };
      dispatch("productLineGetPagedData", pagination);
    };
    var pagination = computed(() =>
      productLinePagination.value &&
      productLinePagination.value.succeeded == true
        ? {
            current: productLinePagination.value.pageNumber,
            total: searchValue.value
              ? productLinePagination.value.recordsFiltered
              : productLinePagination.value.recordsTotal,
            pageSize: productLinePagination.value.pageSize,
            OrderBy: sortDirections,
            searchValue: "",
            sorter: {
              order: sortDirections,
            },
          }
        : {
            current: 1,
            pageSize: 10,
            OrderBy: sortDirections,
            searchValue: "",
            sorter: {
              order: sortDirections,
            },
          }
    );
    const handleDelete = (id) => {
      const confirm = window.confirm("Are you sure delete this?");
      if (confirm) {
        dispatch("productLineApiDataDelete", { id });
      }
      return false;
    };

    const onHandleSearch = () => {
      sortDirections = "ascend";
      pagination = {
        ...pagination,
        current: 1,
        OrderBy: sortDirections,
        pageSize: 10,
        searchValue: searchValue.value,
      };

      dispatch("productLineGetPagedData", pagination);
    };
    const switchUpdateOn = (productLine) => {
      localState.editionMode = true;
      formState.name = productLine.name;
      formState.id = productLine.id;
    };
    const switchUpdateOff = () => {
      localState.editionMode = false;
      formState.name = "";
      formState.id = 0;
    };

    const dataSource = computed(() =>
      ProductLineApi.value && ProductLineApi.value.length
        ? ProductLineApi.value.map((productLine, key) => {
            const { id, name } = productLine;

            return {
              key: key + 1,
              name,
              action: (
                <div class="table-actions">
                  <a-button
                    class="edit"
                    onClick={() => switchUpdateOn(productLine)}
                  >
                    <sdFeatherIcons type="edit" productLine={14} />
                  </a-button>
                  &nbsp;&nbsp;&nbsp;
                  <a-button class="delete" onClick={() => handleDelete(id)}>
                    <sdFeatherIcons type="trash-2" productLine={14} />
                  </a-button>
                </div>
              ),
            };
          })
        : []
    );

    const onSubmit = () => {
      formRef.value
        .validate()
        .then(async () => {
          if (!localState.editionMode) {
            dispatch("productLineSubmitData", {
              formState,
            });
          } else {
            dispatch("productLineUpdateData", {
              formState,
            });
          }
          await nextTick();
          switchUpdateOff();
        })
        .catch((error) => {
          console.log("error", error);
        });
    };

    return {
      ProductLineApi,
      formRef,
      rules,
      onSubmit,
      isLoading,
      localState,
      handleDelete,
      switchUpdateOn,
      switchUpdateOff,
      dataSource,
      HeaderInfo,
      columns,
      onHandleSearch,
      loading,
      sortDirections,
      formState,
      searchValue,
      productLinePagination,
      onHandleTableChange,
      pagination,
    };
  },
};

export default ProductLine;
</script>
<style lang="scss">
.sDash_form-action {
  display: flex;
  justify-content: space-between;
}
</style>
